/* General Styles */
html {
  /*background-image: url("/public/background halftones-02.jpg");*/
  background-color: #fefcef ;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}

body {
  margin: 0;
  padding: 0;
}

.visible-on-hover {
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.5s;
}

.visible-on-hover:hover {
  opacity: 1;
  transition-delay: 0s;
}