.expanding-dot {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #303030;
    transition: all 0.3s;
    transform: translate(-50%, -50%);
    border: 2px solid lightgrey;
}

.expanding-dot.expanded {
    width: 50vh;
    height: auto;
    border-radius: 10px;
    background-color: #e6b2b4;
}

.expanding-dot-image {
    width: 300px;
    height: auto;
}

.expanding-dot-title {
    padding: 10px;
    color: black;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.expanding-dot-text {
    padding: 10px;
    color: black;
    font-size: 16px;
    text-align: left;
}
